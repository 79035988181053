<template>
	<el-dialog width="900px" :title="client.id ? '客户信息' : '创建客户'" :visible.sync="show_dialog" :fullscreen="fullscreen" :custom-class="fullscreen ? 'fullscreen' : 'ufullscreen'" @closed="clear" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-tabs class="client-tabs" v-model="tab_active" v-if="show_dialog">
			<el-tab-pane label="基本信息" name="basic">
				<div style="height: 100%; display: flex;">
					<div style="width: 62%;">
						<el-card style="height: 100%; overflow: auto;" class="scroll-wrapper">
							<el-form ref="create_form" label-width="80px" :model="client" :rules="client_rules" :size="theme.size" :disabled="client.id && is_hide_contact" status-icon>
								<el-form-item label="名称" prop="name">
									<el-input v-model="client.name" autocomplete="off" placeholder="请输入客户名称" clearable>
										<el-select slot="prepend" style="width: 120px;" v-if="!admin_user.shop_id" v-model="client.shop_id" placeholder="城市站点" filterable>
											<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
										</el-select>
										<el-button slot="append" v-if="client.account">{{client.account.name}}</el-button>
										<el-button slot="append" v-else-if="client.id" @click="createUser(client)">注册账户</el-button>
									</el-input>
								</el-form-item>
								<el-form-item label="标签" prop="tags">
									<el-select style="width: 100%;" placeholder="请选择标签" v-model="client.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable allow-create default-first-option>
										<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="所属地区" prop="regions">
									<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="client.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
								</el-form-item>
								<el-form-item label="地址详情" prop="address">
									<el-input v-model="client.address" autocomplete="off" placeholder="请输入详细地址" clearable></el-input>
								</el-form-item>
								<el-form-item label="备注说明" prop="remark">
									<el-input type="textarea" placeholder="请输入备注说明" v-model="client.remark" maxlength="200" show-word-limit></el-input>
								</el-form-item>
								<el-form-item label="状态/等级" style="margin-bottom: 0;">
									<el-col :span="11">
										<el-form-item prop="status">
											<el-select style="width: 100%;" v-model="client.status" placeholder="状态" filterable>
												<el-option v-for="(status, s) in fields.statuses" :label="status" :value="s" :key="s"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="1" style="color: #666; text-align: center;">-</el-col>
									<el-col :span="12">
										<el-form-item prop="grade">
											<el-select style="width: 100%;" v-model="client.grade" placeholder="等级" filterable>
												<el-option v-for="(grade, g) in fields.grades" :label="grade" :value="+g" :key="g"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-form-item>
								<el-form-item label="手机/邮箱" style="margin-bottom: 0;">
									<el-col :span="11">
										<el-form-item prop="mobile">
											<el-input v-model="client.mobile" autocomplete="off" placeholder="请输入手机号码" clearable></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="1" style="color: #666; text-align: center;">-</el-col>
									<el-col :span="12">
										<el-form-item prop="email">
											<el-input v-model="client.email" autocomplete="off" placeholder="请输入电子邮箱" clearable></el-input>
										</el-form-item>
									</el-col>
								</el-form-item>
								<el-form-item label="公司/行业" prop="corporation">
									<el-col :span="9">
										<el-input v-model="client.corporation" autocomplete="off" placeholder="请输入公司名称" clearable></el-input>
									</el-col>
									<el-col :span="1" style="color: #666; text-align: center;">-</el-col>
									<el-col :span="14">
										<el-input v-model="client.industry" autocomplete="off" placeholder="请输入公司行业" clearable>
											<el-checkbox slot="append" v-model="client.is_boss" :true-label="1" :false-label="0">是否老板</el-checkbox>
										</el-input>
									</el-col>
								</el-form-item>
								<el-form-item label="规模/占比" style="margin-bottom: 0;">
									<el-col :span="9">
										<el-form-item prop="scale">
											<el-select style="width: 100%;" v-model="client.scale" placeholder="规模" filterable>
												<el-option v-for="(scale, s) in fields.scales" :label="scale" :value="+s" :key="s"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="1" style="color: #666; text-align: center;">-</el-col>
									<el-col :span="14">
										<el-form-item prop="share">
											<el-input-number v-model="client.share" :min="0" :max="100" label="请输入占比"></el-input-number>
										</el-form-item>
									</el-col>
								</el-form-item>
								<el-form-item v-if="!client.id || !is_hide_contact">
									<el-button type="primary" @click="submitForm('create_form')">提交保存</el-button>
									<el-button type="danger" v-if="client.id">删除客户</el-button>
								</el-form-item>
								<el-form-item v-else>
									<el-button type="primary" @click="applyGet(client)" :disabled="!$utils.checkAuth($api.URI_CLIENTS, 'acquire')">申请获取</el-button>
								</el-form-item>
							</el-form>
							<!-- <template v-if="$utils.index($api.URI_SOCIAL_ACCOUNTS) && client.id && !is_hide_contact">
								<el-divider content-position="left">社交账号</el-divider>
								<el-form ref="account_form" label-width="80px" :model="social" :size="theme.size" status-icon>
									<el-form-item v-for="account in client.social_accounts" :label="social_types[account.type]" :key="account.id">
										<el-input v-model="account.account" :disabled="!$utils.update($api.URI_SOCIAL_ACCOUNTS)" @change="submitAccount(account)" autocomplete="off" placeholder="请输入社交账号" clearable>
											<el-checkbox slot="prepend" v-model="account.is_add" :disabled="!$utils.update($api.URI_SOCIAL_ACCOUNTS)" @change="submitAccount(account)" :true-label="1" :false-label="0">添加</el-checkbox>
											<el-button slot="append" v-if="$utils.delete($api.URI_SOCIAL_ACCOUNTS)" @click="delAccount(account)">删除</el-button>
										</el-input>
									</el-form-item>
									<el-form-item prop="account" :rules="[{ required: true, message: '请输入社交账号', trigger: 'blur' }]" v-if="$utils.create($api.URI_SOCIAL_ACCOUNTS)">
										<el-input v-model="social.account" autocomplete="off" placeholder="请输入社交账号" clearable>
											<el-select slot="prepend" style="width: 120px;" v-model="social.type" placeholder="账号类型" filterable>
												<el-option v-for="(type, t) in social_types" :key="t" :label="type" :value="t"></el-option>
											</el-select>
											<el-button slot="append" @click="submitForm('account_form')">提交</el-button>
										</el-input>
									</el-form-item>
								</el-form>
							</template> -->
						</el-card>
					</div>
					<div style="width: 38%; display: flex; flex-direction: column; padding-left: 20px;">
						<template v-if="client.id">
							<template v-if="client.source_model">
								<el-descriptions title="来源：线索" :column="1" v-if="client.source_model_type === 'App\\Models\\Clue'">
									<el-descriptions-item label="名称">{{client.source_model.name}}</el-descriptions-item>
									<template v-if="!is_hide_contact">
										<el-descriptions-item label="手机号码" v-if="client.source_model.mobile">{{client.source_model.mobile}}</el-descriptions-item>
										<!-- <el-descriptions-item label="电子邮箱" v-if="client.source_model.email">{{client.source_model.email}}</el-descriptions-item> -->
										<!-- <el-descriptions-item label="社交账号" v-if="client.source_model.social_account">{{client.source_model.social_type}} {{client.source_model.social_account}}</el-descriptions-item> -->
									</template>
									<el-descriptions-item label="渠道来源">{{clue_sources[client.source_model.source]}}</el-descriptions-item>
									<el-descriptions-item label="备注说明" :contentStyle="{whiteSpace: 'pre-wrap'}">{{client.source_model.remarks}}</el-descriptions-item>
								</el-descriptions>
								<el-descriptions title="来源：订单" :column="1" v-if="client.source_model_type === 'App\\Models\\Order'">
									<el-descriptions-item label="订单号">{{client.source_model.no}}</el-descriptions-item>
									<template v-if="!is_hide_contact">
										<el-descriptions-item label="联系人">{{client.source_model.linkman}}</el-descriptions-item>
										<!-- <el-descriptions-item label="手机号码">{{client.source_model.mobile}}</el-descriptions-item> -->
									</template>
									<el-descriptions-item label="渠道来源">{{order_sources[client.source_model.source]}}</el-descriptions-item>
								</el-descriptions>
							</template>
							<h3 style="text-align: center; margin-bottom: 50px;" v-else>此客户在客户列表创建。无来源信息~</h3>
							<el-divider content-position="left" v-if="!is_hide_contact">
								<el-link type="primary" :disabled="!$utils.create($api.URI_COMPANIES_SHAREHOLDERS)" @click="editShareholder({client_id: client.id})">关联企业</el-link>
							</el-divider>
							<div style="height: 100%; overflow: auto;" class="scroll-wrapper">
								<el-descriptions size="mini" :title="shareholder.company ? shareholder.company.name : '未知企业名称'" :column="1" v-for="shareholder in client.corp_shareholders" :key="shareholder.id">
									<template v-if="shareholder.company">
										<el-descriptions-item label="行业">{{shareholder.company.industry}}</el-descriptions-item>
										<el-descriptions-item label="资本">{{shareholder.company.capital | format_capital}}</el-descriptions-item>
										<el-descriptions-item label="编码">{{shareholder.company.code}}</el-descriptions-item>
										<el-descriptions-item label="注册">{{shareholder.company.established_at || shareholder.company.created_at}}</el-descriptions-item>
									</template>
									<el-descriptions-item label="股份">{{shareholder.proportion}}%</el-descriptions-item>
									<el-descriptions-item label="职务">{{shareholder.position}}</el-descriptions-item>
									<template slot="extra">
										<el-button type="text" size="mini" :disabled="!$utils.update($api.URI_COMPANIES_SHAREHOLDERS)" @click="editShareholder(shareholder)">编辑</el-button>
										<el-button type="text" size="mini" :disabled="!$utils.delete($api.URI_COMPANIES_SHAREHOLDERS)" @click="delShareholder(shareholder)">删除</el-button>
									</template>
								</el-descriptions>
							</div>
						</template>
						<template v-else>
							<p style="font-size: 12px; line-height: 1.5;">注意：在此表单直接添加客户无来源渠道信息，建议使用线索创建然后转客户~</p>
							<p style="text-align: left;"><el-button type="text" @click="$emit('download')">下载模板</el-button></p>
							<template v-if="importing">
								<h3>正在导入客户 {{import_index}}/{{import_count}}</h3>
								<el-progress :percentage="Math.round((import_index/import_count)*100)"></el-progress>
							</template>
							<el-upload accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importInventories" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_CLIENTS)" v-else>
								<el-button type="primary" icon="el-icon-upload" :disabled="!$utils.create($api.URI_CLIENTS)" :size="theme.size" plain>导入客户</el-button>
							</el-upload>
						</template>
					</div>
				</div>
			</el-tab-pane>
			<template v-if="client.id">
				<el-tab-pane label="跟进情况" name="follows" v-if="$utils.index($api.URI_CLIENTS_FOLLOWS)" lazy>
					<client-follows :client="client" :is-hide-contact="is_hide_contact" v-if="tab_active === 'follows'"></client-follows>
				</el-tab-pane>
				<el-tab-pane label="联系方式" name="contacts" v-if="$utils.index($api.URI_ORDERS)" lazy>
					<client-contacts :client="client" :is-hide-contact="is_hide_contact" v-if="tab_active === 'contacts'"></client-contacts>
				</el-tab-pane>
				<el-tab-pane label="销售订单" name="business" v-if="$utils.index($api.URI_ORDERS)" lazy>
					<client-orders :client="client" :is-hide-contact="is_hide_contact" v-if="tab_active === 'business'"></client-orders>
				</el-tab-pane>
				<template v-if="!is_hide_contact">
					<el-tab-pane label="签约合同" name="contracts" v-if="$utils.index($api.URI_CONTRACTS)" lazy>
						<client-contracts :client="client" v-if="tab_active === 'contracts'"></client-contracts>
					</el-tab-pane>
					<el-tab-pane label="回款记录" name="fees" v-if="$utils.index($api.URI_CLIENTS_FEES)" lazy>
						<client-fees :param="{common_type: 'client', common_id: client.id}" v-if="tab_active === 'fees'"></client-fees>
					</el-tab-pane>
				</template>
			</template>
		</el-tabs>
		<el-dialog width="380px" title="客户企业" :visible.sync="shareholder_dialog" :fullscreen="theme.width < 380" @closed="$refs['shareholder_form'].resetFields(); shareholder = {}" append-to-body destroy-on-close>
			<el-form ref="shareholder_form" label-position="top" :model="shareholder" :size="theme.size" status-icon>
				<el-form-item label="企业" prop="company_id" :rules="[{ required: true, message: '请输入企业ID', trigger: 'blur' }]">
					<el-input v-model="shareholder.company_id" autocomplete="off" placeholder="请输入企业ID" clearable></el-input>
				</el-form-item>
				<el-form-item label="职位" prop="position" :rules="[{ required: true, message: '请输入企业职位', trigger: 'blur' }]">
					<el-input v-model="shareholder.position" autocomplete="off" placeholder="请输入企业职位" clearable></el-input>
				</el-form-item>
				<el-form-item label="占比" prop="proportion">
					<el-input-number v-model="shareholder.proportion" :min="0" :max="max_proportion" label="请输入股份占比"></el-input-number>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('shareholder_form')">提交保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="480px" :title="'申请获取 '+apply.name" :visible.sync="apply_dialog" :fullscreen="theme.width < 480" append-to-body>
			<el-form ref="acquire_client" label-position="top" :model="apply" :size="theme.size" status-icon>
				<el-form-item label="理由" prop="comment" :rules="[{ required: true, message: '请输入申请理由。', trigger: 'blur' }]">
					<!-- <el-input type="textarea" :placeholder="'请输入申请理由。非'+theme.group+'权限账户每天最大限制领取1名客户'" rows="3" maxlength="255" v-model="apply.comment" show-word-limit></el-input> -->
					<el-input type="textarea" :placeholder="'请输入申请理由。每账户每天最大限制领取1名客户'" rows="3" maxlength="255" v-model="apply.comment" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="下次跟进" prop="next_at" :rules="[{ required: true, message: '必须选择下次跟进时间。', trigger: 'blur' }]">
					<el-date-picker type="datetime" placeholder="选择日期时间" v-model="apply.next_at" :picker-options="pickerOptions" :editable="false"></el-date-picker>
				</el-form-item>
				<el-form-item label="附件" prop="attach">
					<el-upload
						:action="$api.URI_UPLOADS"
						:data="{type: follow_attach_cfg.type}"
						:accept="follow_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l); }"
						:on-remove="(f, l) => { apply = {...apply, attachments: l}; }"
						:before-upload="beforeFollowAttach"
						:file-list="apply.attachments"
						:limit="9"
						multiple>
						<el-button type="text" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" class="el-upload__tip">只能上传 {{follow_attach_cfg.ext.join('/').toUpperCase()}} 文件，且单文件大小不超过 {{follow_attach_cfg.format_size_unit}}</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('acquire_client')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-dialog>
</template>

<style type="text/css">
	.fullscreen .el-dialog__body {
		height: calc(100% - 54px);
	}
	.ufullscreen .el-dialog__body {
		height: calc(100vh - 30vh);
	}
	.client-tabs {
		height: 100%;
		display: flex;
		margin-top: -30px;
		flex-direction: column;
	}
	.client-tabs .el-tabs__content,
	.client-tabs .el-tab-pane {
		height: 100%;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import clientFees from './fees';
	import clientOrders from './orders';
	import clientFollows from './follows';
	import clientContacts from './contacts';
	import clientContracts from './contracts';

	export default {
		components: {
			clientFees,
			clientOrders,
			clientFollows,
			clientContacts,
			clientContracts
		},
		computed: {
			...mapState(['theme', 'regions', 'clues', 'orders', 'clients', 'social_accounts', 'admin_user', 'models']),
			follow_attach_cfg () {
				return this.$utils.uploadConfig('follow_attachment');
			},
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			social_types () {
				return this.social_accounts.types || [];
			},
			clue_sources () {
				return this.clues.sources || [];
			},
			order_sources () {
				return this.orders.sources || [];
			},
			shops () {
				const { data } = this.models.shops;
				return data || [];
			},
			fields () {
				return this.models.clients || {};
			},
			fullscreen () {
				if (this.$utils.isiPad()) return true;
				if (this.$utils.isMobile()) return true;
				if (this.theme.width < 750) return true;
				return false;
			},
			max_proportion () {
				var max = 100;
				if (!this.shareholder_dialog) return max;
				const { corp_shareholders } = this.client;
				const { client_id } = this.shareholder;
				for (let shareholder of corp_shareholders) {
					if (shareholder.client_id === client_id) continue;
					max = max - shareholder.proportion;
				}
				return max;
			},
			// 流入公海不展示联系信息，防止业务在公海联系引起撞客户情况，必须领到名下才可以联系 隐藏
			is_hide_contact () {
				return this.client.employee_id ? false : true;
			}
		},
		props: {
			tableTemp: {
				type: Object
			}
		},
		methods: {
			msgError (msg) {
				const { is_mobile } = this.theme;
				return is_mobile ? this.$toast.fail(msg) :  this.$message.error(msg);
			},
			clear () {
				this.importing = false;
				this.tab_active = 'basic';
				this.client = {};
			},
			onSuccess (list) {
				this.apply = {
					...this.apply,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			async open (id) {
				if (id) {
					const res = await this.$http.get(this.$api.URI_CLIENTS, {params: {action: 'update', id}, headers: {loading: true}});
					const { code, msg, result } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.client = { ...result, regions: result.regions.map((r) => { return r.id; }), tags: result.tags.map((r) => { return r.name; }) };
				}
				this.show_dialog = true;
			},
			async applyGet (r) {
				this.apply_dialog = true;
				this.apply = { client_id: r.id, name: r.name };
			},
			beforeFollowAttach (file) {
				const { size, format_size_unit } = this.follow_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传跟进附件不能超过 '+format_size_unit);
				return is;
			},
			delAccount (a) {
				this.$confirm(a.account, '确定要删除账户吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_SOCIAL_ACCOUNTS, {params: { action: 'delete', id: a.id }, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.open(a.client_id);
				});
			},
			editShareholder (a) {
				this.shareholder = {...a};
				this.shareholder_dialog = true;
			},
			delShareholder (a) {
				this.$confirm(a.company.name, '确定要删除企业关联吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COMPANIES_SHAREHOLDERS, {params: { action: 'delete', id: a.id }, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.open(a.client_id);
				});
			},
			createUser ({ id, name, mobile, account }) {
				if (account) return this.$message.error('选中 '+name+' 客户已经注册绑定用户账户！');
				if (!name) return this.$message.error('选中未知客户名称！');
				if (!mobile) return this.$message.error('选中 '+name+' 客户暂无手机号码信息！');
				this.$confirm('确定为【'+name+'】客户注册绑定账户吗？', '注册账户', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.$message.error('待接入');
					const res = await this.$http.post(this.$api.URI_USERS, {id, action: 'create', type: 'client_id'});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.open(id);
						}
					});
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'acquire_client':
							this.submitClient({action: 'acquire', ...this.apply}, () => {
								this.show_dialog = false;
								this.apply_dialog = false;
								this.$emit('refresh');
							});
						break;
						case 'create_form':
							this.submitClient(this.client, () => {
								if (this.client.id) {
									this.open(this.client.id)
								} else {
									this.$emit('refresh');
									this.show_dialog = false;
								}
							});
						break;
						case 'account_form':
							if (!this.social.type) return this.$message.error("请选择账号类型！");
							this.submitAccount({...this.social, client_id: this.client.id});
						break;
						case 'shareholder_form':
							this.submitShareholder(this.shareholder);
						break;
					}
				});
			},
			async submitClient (d, onClose) {
				const res = await this.$http.post(this.$api.URI_CLIENTS, d);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			async submitAccount (data) {
				const res = await this.$http.post(this.$api.URI_SOCIAL_ACCOUNTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.open(this.client.id);
						this.$refs['account_form'].resetFields();
					}
				});
			},
			async submitShareholder (data) {
				const res = await this.$http.get(this.$api.URI_COMPANIES_SHAREHOLDERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.open(this.client.id);
						this.shareholder_dialog = false;
						this.$refs['shareholder_form'].resetFields();
					}
				});
			},
			importInventories ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter } = this.tableTemp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						this.importing = true;
						if (data.length <= 0) {
							this.importing = false;
							return this.$message.error("无需要导入记录！");
						}
						this.import_count = data.length;
						for (var i in data) {
							if (!this.importing) {
								this.closeImported(data);
								break;
							}
							const { name, mobile, email, region, tags } = data[i];
							this.import_index++;
							if (!name) {
								data[i].status = "提交名称不能为空";
								continue;
							}
							if (!mobile && !email) {
								data[i].status = "手机/邮箱必填其中一项";
								continue;
							}
							if (region && region < 0) {
								data[i].status = "提交地区ID必须大于0，请参考地区Sheets";
								continue;
							}
							const m_reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
							if (mobile && !m_reg.test(mobile)) {
								data[i].status = "手机格式不正确";
								continue;
							}
							const e_reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
							if (email && !e_reg.test(email)) {
								data[i].status = "邮箱格式不正确";
								continue;
							}
							const res = await this.$http.post(this.$api.URI_CLIENTS, {...data[i], tags: tags ? tags.replace("，", ",").split(",") : null});
							const { code, msg } = res.data;
							if (code != 0) {
								data[i].status = msg;
								continue;
							}
							data[i].status = '导入成功';
						}
						if (this.import_index === this.import_count) {
							setTimeout(() => {
								this.closeImported(data);
							}, 1000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			closeImported (data) {
				const { thead, filter } = this.tableTemp;
				this.importing = false;
				this.import_index = 0;
				this.import_count = 0;
				this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "客户导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
			}
		},
		data() {
			const checkEmail = (rule, value, callback) => {
				const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的电子邮箱'));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				// 有带转接格式，暂时去掉验证
				// const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
				// if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号码'));
				return callback();
			}
			return {
				tags: [],
				apply: {},
				client: {},
				social: {},
				shareholder: {},
				import_index: 0,
				import_count: 0,
				importing: false,
				show_dialog: false,
				apply_dialog: false,
				shareholder_dialog: false,
				tags_loading: false,
				tab_active: 'basic',
				client_rules: {
					name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
					mobile: [{ validator: checkMobile, trigger: ['blur', 'change'] }],
					email: [{ validator: checkEmail, trigger: ['blur', 'change'] }],
				},
				pickerOptions: {
					disabledDate (time) {
						return time.getTime() < Date.now();
					}
				}
			}
		}
	};
</script>


