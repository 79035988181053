<template>
	<div style="height: 100%; display: flex;">
		<div style="width: 58%; padding: 10px;">
			<div style="height: 100%; padding-right: 10px; overflow: auto;" class="scroll-wrapper" v-infinite-scroll="infinite" v-if="clients_follows.length">
				<el-timeline>
					<el-timeline-item placement="top" v-for="follow in clients_follows" :key="follow.id" :timestamp="(follow.employee ? follow.employee.name : '未知')+'【'+(statuses[follow.status] || '未知状态')+']'+' 提交于 '+follow.created_at+(follow.next_at ? ' 下次跟进 '+follow.next_at : '')">
						<el-card :body-style="{whiteSpace: 'break-spaces'}">
							{{follow.comment}}
							<div class="attachments" v-if="follow.attachments">
								<template v-for="attachment in follow.attachments">
									<el-image fit="cover" class="f_attachment" :key="attachment.id" :src="attachment.url" @click="openFile(attachment.url)">
										<i slot="error" class="el-icon-document" @click="openFile(attachment.url)"></i>
									</el-image>
								</template>
							</div>
							<div style="margin-top: 10px;" v-if="follow.course">{{fields.courses[follow.course]}}，{{fields.learns[follow.learn]}}</div>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
			<h3 style="text-align: center;" v-else>暂无跟进记录</h3>
		</div>
		<div style="width: 42%; padding: 20px;" v-if="!isHideContact">
			<el-card style="height: 100%; overflow: auto;" class="scroll-wrapper">
				<el-form ref="follows_form" label-position="top" label-width="80px" size="mini" :model="follow" :rules="follow_rules" status-icon>
					<el-form-item label="直播参课" prop="course">
						<el-select v-model="follow.course" placeholder="请选择直播参课" style="width: 100%;" clearable filterable>
							<el-option v-for="(course, c) in fields.courses" :label="course" :value="c" :key="c"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="线上直播" prop="learn">
						<el-radio-group v-model="follow.learn">
							<el-radio v-for="(learn, l) in fields.learns" :label="l" :key="l">{{learn}}</el-radio>
						</el-radio-group>
						<div style="font-size: 12px; color: #999; line-height: 1.5;" v-if="records.length">
							<div v-for="record in records" :key="record.id">{{fields.courses[record.course]}}，{{fields.learns[record.learn]}}</div>
						</div>
					</el-form-item>
					<el-form-item label="备注说明" prop="comment">
						<el-input type="textarea" v-model="follow.comment" placeholder="请输入备注说明。提交后不能修改" rows="3" maxlength="255" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item label="下次跟进" prop="next_at">
						<el-date-picker type="datetime" placeholder="选择日期时间" v-model="follow.next_at" :picker-options="pickerOptions" :editable="false"></el-date-picker>
					</el-form-item>
					<el-form-item label="上传附件" prop="attachments">
						<el-upload
							slot="label"
							ref="follows_upload"
							list-type="picture"
							:action="$api.URI_UPLOADS"
							:data="{type: follow_attach_cfg.type}"
							:accept="follow_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { onSuccess(l); }"
							:on-remove="(f, l) => { follow = {...follow, attachments: l}; }"
							:before-upload="beforeFollowAttach"
							:file-list="follow.attachments"
							:limit="9"
							multiple>
							<el-button type="text" icon="el-icon-upload">上传附件</el-button>
							<p slot="tip" class="el-upload__tip" style="font-size: 12px; line-height: 1.5;">只能上传 {{follow_attach_cfg.ext.join('/').toUpperCase()}} 文件，且单文件大小不超过 {{follow_attach_cfg.format_size_unit}}</p>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('follows_form')">提交跟进</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</div>
	</div>
</template>

<style type="text/css">
	.attachments {
		display: flex;
		flex-wrap: wrap;
		margin: 10px -10px 0;
	}
	.attachments .f_attachment {
		width: 80px;
		height: 80px;
		font-size: 32px;
		line-height: 80px;
		margin-right: 10px;
		text-align: center;
		background-color: #f1f1f1;
		border-radius: 5px;
		cursor: pointer;
	}
	.attachments .f_attachment:hover {
		color: #c5b388;	
	}
	.scroll-wrapper {
		&::-webkit-scrollbar-track-piece {
			background-color: #f1f1f1;
			-webkit-border-radius: 3px;
		}
		&::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}
		&::-webkit-scrollbar-thumb {
			height: 30px;
			outline-offset: -2px;
			border: 2px solid #fff;
			background-color: #e1d6ba;
			-webkit-border-radius: 7px;
		}
		&::-webkit-scrollbar-thumb:hover {
			height: 30px;
			background-color: #b7a06a;
			-webkit-border-radius: 8px;
		}
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			client: {
				type: Object
			},
			isHideContact: {
				type: Boolean
			}
		},
		computed: {
			...mapState(['theme', 'oa_users', 'models']),
			format_user () {
				return this.oa_users || [];
			},
			follow_attach_cfg () {
				return this.$utils.uploadConfig('follows');
			},
			fields () {
				return this.models.client_follows || {};
			},
			statuses () {
				const { statuses } = this.models.clients;
				return statuses || []
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			onSuccess (list) {
				this.follow = {
					...this.follow,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			beforeFollowAttach (file) {
				const { size, format_size_unit } = this.follow_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传跟进附件不能超过 '+format_size_unit);
				return is;
			},
			async getClientsFollows (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS_FOLLOWS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.clients_follows = result.current_page === 1 ? result.data : this.clients_follows.concat(result.data);
				this.records = result.records;
				this.params = {
					...params,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'follows_form':
							this.submitFollow(this.follow);
						break;
					}
				});
			},
			async submitFollow (data) {
				const res = await this.$http.post(this.$api.URI_CLIENTS_FOLLOWS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getClientsFollows({...this.params, page: 1});
						this.$refs['follows_form'].resetFields();
						this.$refs['follows_upload'].clearFiles();
					}
				})
			},
			infinite () {
				const { page, total } = this.params;
				if (page < total) {
					this.getClientsFollows({...this.params, page: page + 1});
				}
			}
		},
		data() {
			return {
				follow: {},
				records: [],
				clients_follows: [],
				params: {
					perPage: 10
				},
				follow_rules: {
					teacher: [{ required: true, message: '请选择直播老师', trigger: 'blur' }],
					comment: [{ required: true, message: '请输入备注', trigger: 'blur' }]
				},
				pickerOptions: {
					disabledDate (time) {
						return time.getTime() < Date.now();
					}
				}
			}
		},
		mounted () {
			const { id: client_id } = this.client;
			this.follow = { client_id, learn: 0 };
			this.getClientsFollows({...this.params, client_id});
		}
	};
</script>