<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<div class="scroll-wrapper" style="height: 100%; padding-right: 10px; overflow: auto;">
			<el-button type="primary" size="mini" icon="el-icon-edit-outline" style="margin-bottom: 10px;" :disabled="!$utils.create('orders')" @click="$refs['create_goods'].open({shop_id: client.shop_id, buyer_id: client.id, buyer_type: 'App\\Models\\Client'})" v-if="!isHideContact" plain>开单</el-button>
			<template v-if="table.length">
				<div style="position: relative;" v-for="order in table" :title="'No.'+order.no" :key="order.no">
					<el-descriptions class="order" :labelStyle="{width: '70px'}" :contentStyle="{minWidth: '70px'}" :size="theme.size" :column="4" border>
						<template slot="title">
							<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="$refs['order_show'].open(order)" :disabled="isHideContact">No.{{order.no}}</el-link>
							<p style="font-size: 12px; display: inline-block; padding-left: 10px;" v-if="order.seller">{{order.seller.name}} 创建于 {{order.created_at}}</p>
						</template>
						<template slot="extra" v-if="!isHideContact">
							<el-button type="text" size="mini" @click="$refs['order_show'].onDelete(order)" :disabled="!$utils.delete($api.URI_ORDERS) || $utils.closeOrder(order.status)">删除</el-button>
						</template>
						<el-descriptions-item label="商品">{{order.goods_price | format_price}} 元</el-descriptions-item>
						<el-descriptions-item label="优惠">{{order.discount_price | format_price}} 元</el-descriptions-item>
						<el-descriptions-item label="运费">{{order.freight_price | format_price}} 元</el-descriptions-item>
						<el-descriptions-item label="交付信息">
							<p v-if="!order.address">无需物流配送</p>
							<template v-else>
								<p><i class="el-icon-mobile-phone"></i> {{order.mobile}} {{order.linkman}}</p>
								<p><i class="el-icon-map-location"></i> <span v-for="region in order.regions" :key="region.id">{{region.name}}</span> {{order.address}}</p>
							</template>
						</el-descriptions-item>
						<el-descriptions-item label="实付">{{order.paid_price | format_price}} 元</el-descriptions-item>
						<el-descriptions-item label="支付时间">{{order.paid_at || '无'}}</el-descriptions-item>
						<el-descriptions-item label="交付时间">{{order.shipped_at || '无'}}</el-descriptions-item>
						<el-descriptions-item label="备注说明">{{order.remarks || '/'}}</el-descriptions-item>
						<el-descriptions-item label="完成时间">{{order.finished_at || '无'}}</el-descriptions-item>
						<el-descriptions-item label="状态">
							<el-link type="primary" @click="$refs['order_show'].onStatus(order)" :disabled="isHideContact">{{statuses[order.status] || '未知'}}</el-link>
						</el-descriptions-item>
						<el-descriptions-item label="订购产品">
							<p v-if="!order.order_goods.length">暂无交付</p>
							<template v-else>{{order.order_goods.map((r) => { return r.title; }).join('、')}}</template>
						</el-descriptions-item>
					</el-descriptions>
					<div v-if="order.invoices.length" style="display: inline-block; font-size: 30px; transform: rotate(30deg); position: absolute; top: 50%; left: 50%; font-weight: bold; opacity: 0.3; color: #ff0000; border: 1px solid #ff0000; padding: 5px 20px;">已开票</div>
				</div>
			</template>
			<el-divider v-else>暂无记录</el-divider>
		</div>
		<order-goods ref="create_goods" @refresh="getOrders(params)"></order-goods>
		<order-create ref="create_order" @refresh="(e) => { $refs['order_show'].open(e) }"></order-create>
		<show-fee ref="show_fee" @refresh="getOrders(params)"></show-fee>
		<order-show ref="order_show" :employees="employees" @refresh="getOrders(params)" @show-contract="(contract) => { $refs['contract_dialog'].open(contract); }" @contract-status="(c) => { $refs['contract_dialog'].onStatus(c) }" @edit="(c) => { $refs['create_order'].open(c); }"></order-show>
		<show-contract ref="contract_dialog" @refresh="getOrders(params)" @create-fee="(fee) => { $refs['show_fee'].open(fee); }"></show-contract>
	</div>
</template>

<style type="text/css">
	.order {
		margin-bottom: 15px;
	}
	.order .el-descriptions__header {
		color: #666;
		margin-bottom: 0;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import orderGoods from '@/pages/oms/orders/products/create';
	import orderShow from '@/pages/oms/orders/show';
	import orderCreate from '@/pages/oms/orders/create';
	import showContract from '../contracts/show';
	import showFee from '../fees/show';

	export default {
		components: {
			orderShow,
			orderGoods,
			orderCreate,
			showContract,
			showFee
		},
		props: {
			client: {
				type: Object
			},
			isHideContact: {
				type: Boolean
			}
		},
		computed: {
			...mapState(['theme', 'regions', 'orders']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			statuses () {
				if (!this.orders) return [];
				return this.orders.statuses;
			}
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		methods: {
			async getOrders (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ORDERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.table =  result.data;
				this.employees =  result.employees;
				this.params = {
					...params,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				order: {},
				table: [],
				employees: [],
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			const { id: client_id } = this.client;
			this.getOrders({...this.params, client_id});
		}
	};
</script>