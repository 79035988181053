<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<div class="scroll-wrapper" style="height: 100%; padding-right: 10px; overflow: auto;" v-if="table.length">
			<el-descriptions class="contract" v-for="contract in table" :title="'No.'+contract.no" :key="contract.no" :size="theme.size" border>
				<template slot="title">
					<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="showContract(contract)">No.{{contract.no}}</el-link>
					<p style="font-size: 12px; display: inline-block; padding-left: 10px;">{{contract.employee.name}} 创建于 {{contract.created_at}}</p>
				</template>
				<template slot="extra">
					<el-button type="text" size="mini" @click="$refs['contract_dialog'].delContract(contract)" :disabled="!$utils.delete($api.URI_CONTRACTS)">删除</el-button>
				</template>
				<el-descriptions-item label="订单">
					<template v-if="contract.order">
						<p>No.{{contract.order.no}}</p>
						<p style="color: #999; line-height: 1.2; margin-bottom: 5px;" v-for="goods in contract.order.order_goods" :key="goods.id">{{goods.title}}</p>
					</template>
					<p v-else>暂无～</p>
				</el-descriptions-item>
				<el-descriptions-item label="有效时间">{{contract.start_at | format_at}} 至 {{contract.end_at | format_at}}</el-descriptions-item>
				<el-descriptions-item label="金额">
					<el-tooltip effect="dark" content="已审核支付 / 签约金额">
						<span><i class="el-icon-money"></i> {{contract | fees_total}} / {{(contract.price/100).toFixed(2)+' 元'}}</span>
					</el-tooltip>
				</el-descriptions-item>
				<el-descriptions-item label="备注说明">{{contract.comment || '/'}}</el-descriptions-item>
				<el-descriptions-item label="签约时间">{{contract.signed_at || '暂无'}}</el-descriptions-item>
				<el-descriptions-item label="状态">
					<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+contract.approval.sp_no" v-if="contract.approval">
						<el-link type="primary" @click="$refs['contract_dialog'].onStatus(contract)">{{statuses[contract.status] || '未知'}}</el-link>
					</el-tooltip>
					<el-link type="primary" @click="$refs['contract_dialog'].onStatus(contract)" v-else>{{statuses[contract.status] || '未知'}}</el-link>
				</el-descriptions-item>
				<el-descriptions-item label="乙方代理">
					<p v-if="contract.person"><i class="el-icon-mobile-phone"></i> {{contract.person}} <template v-if="contract.mobile">, {{contract.mobile}}</template></p>
					<p v-if="contract.regions.length"><i class="el-icon-map-location"></i> {{contract.regions.map((r) => r.name).join('')}} {{contract.address}}</p>
				</el-descriptions-item>
			</el-descriptions>
		</div>
		<el-divider v-else>暂无记录</el-divider>
		<show-contract ref="contract_dialog" @refresh="getContracts(params)" @create-fee="(fee) => { $refs['fee_dialog'].open(fee); }"></show-contract>
		<show-fee ref="fee_dialog" @refresh="getContracts(params)"></show-fee>
	</div>
</template>

<style type="text/css">
	.contract {
		margin-bottom: 15px;
	}
	.contract .el-descriptions__header {
		color: #666;
		margin-bottom: 0;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import showFee from '../fees/show';
	import showContract from '../contracts/show';

	export default {
		components: {
			showFee,
			showContract
		},
		props: {
			client: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme', 'contracts']),
			statuses () {
				if (!this.contracts) return [];
				return this.contracts.statuses;
			}
		},
		filters: {
			fees_total ({client_fees}) {
				var total = 0;
				if (!client_fees) return total;
				client_fees.map((f) => {
					total += f.price;
				})
				return total/100;
			},
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async getContracts (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CONTRACTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.table = result.data;
				this.params = {
					...params,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			},
			showContract (r) {
				this.$refs['contract_dialog'].open({
					...r,
					order_no: r.order ? r.order.no : '',
					price: r.price/100,
					regions: r.regions.map((r) => r.id),
					max_price: r.price/100,
					effective_at: [new Date(r.start_at), new Date(r.end_at)]
				});
			}
		},
		data() {
			return {
				table: [],
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			if (!this.client) return false;
			const { id: client_id } = this.client;
			this.getContracts({...this.params, client_id});
		}
	};
</script>