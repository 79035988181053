<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<el-table ref="contacts_table" class="scroll-wrapper absolute" height="100%" :data="contacts" :size="theme.size">
			<el-table-column label="名称" prop="name" min-width="80">
				<el-link slot-scope="scope" type="primary" @click="contact = {...scope.row}; create_dialog = true;" :disabled="!$utils.update($api.URI_CONTACTS)">{{scope.row.name}}</el-link>
			</el-table-column>
			<el-table-column label="方式" min-width="40" :formatter="({type}) => { return types[type] || '未知'; }"></el-table-column>
			<el-table-column label="账户" prop="account" min-width="100"></el-table-column>
			<el-table-column label="职位" prop="position" min-width="80"></el-table-column>
			<el-table-column label="备注说明" prop="remark" min-width="160" show-overflow-tooltip></el-table-column>
			<el-table-column label="更新时间" prop="updated_at" min-width="120"></el-table-column>
			<el-table-column label="创建时间" prop="created_at" min-width="120"></el-table-column>
			<el-table-column width="60">
				<el-link slot="header" type="primary" @click="create_dialog = true" :disabled="!$utils.create($api.URI_CONTACTS)">添加</el-link>
				<template slot-scope="scope">
					<el-popconfirm title="确定要恢复这条记录吗？" @confirm="handleContact('restore', scope.row)" v-if="scope.row.deleted_at">
						<el-link slot="reference" type="primary" :disabled="!$utils.restore($api.URI_CONTACTS)">恢复</el-link>
					</el-popconfirm>
					<el-popconfirm title="确定要删除这条记录吗？" @confirm="handleContact('delete', scope.row)" v-else>
						<el-link slot="reference" type="primary" :disabled="!$utils.delete($api.URI_CONTACTS)">删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog width="420px" :title="contact.id ? '编辑联系' : '添加联系'" :visible.sync="create_dialog" :fullscreen="theme.width < 420" append-to-body>
			<el-form ref="create_form" label-width="80px" :model="contact" :rules="contact_rules" :size="theme.size" status-icon>
				<el-form-item label="名称" prop="name">
					<el-input v-model="contact.name" autocomplete="off" placeholder="请输入名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="职位" prop="position">
					<el-input v-model="contact.position" autocomplete="off" placeholder="请输入职位" clearable></el-input>
				</el-form-item>
				<el-form-item label="账号" prop="account">
					<el-input v-model="contact.account" autocomplete="off" placeholder="请输入账号" clearable>
						<el-select slot="prepend" style="width: 100px;" v-model="contact.type" placeholder="方式" filterable clearable>
							<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="备注说明" prop="remark">
					<el-input v-model="contact.remark" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('create_form')">提交保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'models']),
			types () {
				const { contacts } = this.models;
				if (!contacts) return {};
				return contacts.types || {};
			}
		},
		props: {
			client: {
				type: Object
			}
		},
		methods: {
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					const { id: common_id } = this.client;
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'create_form':
							this.submitContacts({common_type: 'App\\Models\\Client', common_id, ...this.contact});
						break;
					}
				});
			},
			async submitContacts (data) {
				const res = await this.$http.post(this.$api.URI_CONTACTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getContacts(this.params);
						this.create_dialog = false;
					}
				});
			},
			async handleContact (action, { id }) {
				const res = await this.$http.get(this.$api.URI_CONTACTS, {params: { action, id }, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getContacts(this.params);
			},
			async getContacts (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CONTACTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.contacts = result.data;
				this.params = {
					...params,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				contact: {},
				contacts: [],
				create_dialog: false,
				contact_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					type: [{ required: true, message: '请选择方式', trigger: ['blur', 'change'] }],
					account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
				},
				params: {}
			}
		},
		mounted () {
			const { id: common_id } = this.client;
			this.getContacts({...this.params, common_type: 'App\\Models\\Client', common_id});
		}
	};
</script>